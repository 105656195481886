import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import DefaultLayout from '../layouts';
import Hero from '../components/Hero/Hero';
import styles from '../components/ShopProduct/styles.module.scss';

const BlogTemplate = ({ pageContext }) => {
  const {
    post: {
      title,
      body: {
        childMarkdownRemark: { html },
      },
      description: {
        childMarkdownRemark: { html: descHtml },
      },
      heroImage,
    },
    metaData,
  } = pageContext;

  return (
    <DefaultLayout metaData={metaData}>
      <div className="base">
        {heroImage && <Hero image={heroImage} />}
        <div className="container container--compact blog">
          <div className="breadcrumbs">
            <Link to="/blog">&lsaquo; Back to all articles</Link>
          </div>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: descHtml }} />
          <div
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          ></div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default BlogTemplate;
